import React from "react";
import { Layout, Seo } from "components/common";
import { Intro, About, Android, Install, Contact, Data, Explanation, Team} from "components/landing";

const Home = () => (
	<Layout>
		<Seo />
		<Intro />
		<Explanation />
		<About />
		<Install />
		<Android />
		<Data />
		<Team/>
		<Contact />
	</Layout>
);

export default Home;
